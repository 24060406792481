<template>
  <div class="px-6 py-2 mb-12 flex flex-col font-semibold lg:mt-32">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button class="focus:outline-none" @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Overview</h2>
    </header>
    <div class="flex justify-between items-center">
      <span>{{ dateValue }}</span>
      <button
        class="p-3 rounded focus:outline-none"
        @click="showModal = true"
        :disabled="isLoading"
      >
        <SettingIcon class="h-5" />
      </button>
    </div>
    <BPageLoader v-if="isLoading" class="flex-grow" />
    <ul class="grid grid-cols-2 gap-4 my-6" v-else>
      <li
        class="rounded-lg px-4 py-8 text-xs text-center flex flex-col items-center"
        style="background: #b8881f"
      >
        <span
          class="h-10 w-10 rounded-full justify-center flex items-center"
          style="background-color: #feecc4"
        >
          <Fundraiser2Icon class="h-5 stroke-2 stroke-current" style="stroke: #b8881f" />
        </span>
        <span class="text-lg text-text-secondary mb-3 mt-6">
          {{ formatNumber(data.totalFundraisers) }}
        </span>
        <span style="color: #feecc4">Total Fundraisers</span>
      </li>
      <li
        class="rounded-lg px-4 py-8 text-xs text-center flex flex-col items-center"
        style="background: #6c63ff"
      >
        <span
          class="h-10 w-10 rounded-full justify-center flex items-center"
          style="background-color: #d8d6ff"
        >
          <ProfileIcon class="h-5 stroke-current stroke-2" style="stroke: #6c63ff" />
        </span>
        <span class="text-lg text-text-secondary mb-3 mt-6">
          {{ formatNumber(data.totalBlessers) }}
        </span>
        <span style="color: #d8d6ff">Total Blessers</span>
      </li>
      <li
        class="rounded-lg px-4 py-8 text-xs text-center flex flex-col items-center"
        style="background: #775384"
      >
        <span
          class="h-10 w-10 rounded-full justify-center flex items-center"
          style="background-color: #f3cfff"
        >
          <CashIcon class="h-5 stroke-current" style="stroke: #775384" />
        </span>
        <span class="text-lg text-text-secondary my-3 mt-6">
          ₦{{ formatNumber(data.totalBlessings) }}
        </span>
        <span style="color: #f3cfff">Total Blessings</span>
      </li>
      <li
        class="rounded-lg px-4 py-8 text-xs text-center flex flex-col items-center"
        style="background: #ed713c"
      >
        <span
          class="h-10 w-10 rounded-full justify-center flex items-center"
          style="background-color: #ffe5da"
        >
          <UpdateIcon class="h-5 fill-current" style="fill: #ed713c" />
        </span>
        <span class="text-lg text-text-secondary my-3 mt-6">
          ₦{{ formatNumber(data.totalWithdrawals) }}
        </span>
        <span style="color: #FFE5DA">Total Withdrawals</span>
      </li>
    </ul>
  </div>
  <BModal :isOpen="showModal" @close="showModal = false">
    <ul>
      <li
        class="py-2 my-1 xs:my-3 w-full cursor-pointer relative hover:bg-bg-alternate-5 text-sm font-semibold"
        v-for="(item, index) in options"
        :key="`${item}-${index}`"
        @click="handleSelect(item)"
      >
        <TickIcon
          v-if="dateValue === item"
          class="absolute right-0 h-5 fill-current"
          style="fill: #037ed8"
        />
        {{ item }}
      </li>
    </ul>
  </BModal>
</template>

<script>
import BModal from '@/components/ui/BModal';
import { ref, toRefs, watch } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { getUserAccountOverview } from '@/services/api';
import { useStore } from 'vuex';

export default {
  name: 'Overview',

  components: { BModal },

  setup() {
    const options = ['Today', 'This Week', 'This Month', 'This Year', 'All Time'];
    const dateValue = ref('Today');
    const showModal = ref(false);
    const store = useStore();
    const authUser = store.state.auth.authData;
    const [response, fetchUserAccountOverview] = useApi(getUserAccountOverview);

    watch(
      dateValue,
      (newValue) => {
        fetchUserAccountOverview({
          userId: authUser.id,
          filter: newValue.replace(/^This\s+/, ''),
        });
      },
      { immediate: true }
    );

    const handleSelect = (value) => {
      dateValue.value = value;
      showModal.value = false;
    };

    const formatNumber = (num) => {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    };

    return {
      options,
      dateValue,
      showModal,
      handleSelect,
      ...toRefs(response),
      formatNumber,
    };
  },
};
</script>
